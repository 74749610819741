<template>
  <div id="resident">
    <!-- <Tabbar />
    <Bottombox />
    <Fuwu /> -->
    <!-- 居民服务 -->
    <div class="baner">
      <el-carousel
        :interval="4000"
        style="width: 100%"
        arrow="always"
        height="780px"
      >
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <el-image
            :class="className"
            fit="cover"
            style="width: 100%; height: 576px;"
            :src="bannerdd.length !== 0?'https://gxzw.linjiaxiaoda.com/picture/prod-api' + item.image:item.image"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 三个内容 -->
    <div class="box">
      <div class="box-li flex JCspaceBetween ALcenter">
        <div class="box-li-lbg"></div>
        <div class="box-li-rbox">
          <div class="box-li-rbox-poa1"></div>
          <div class="fs36 fw6">智慧管家</div>
          <div class="fs24 hui999" style="margin-top: 10px">Wise steward</div>
          <div class="fs16 hui333 box-li-rbox-lh" style="margin-top: 36px">
            全自研开发智慧小区平台，实现“手机在手，畅行无忧”的出入体验，无感刷脸开门、通行二维码、扫码开门、非触碰式感应开门。同时包含物业缴费、运营等一体化建设，全方位构建智慧社区服务运营体系
          </div>
        </div>
      </div>
      <div class="box-li flex JCspaceBetween ALcenter">
        <div class="box-li-rbox">
          <div class="box-li-rbox-poa2"></div>
          <div class="fs36 fw6">生活服务</div>
          <div class="fs24 hui999" style="margin-top: 10px">Life service</div>
          <div class="fs16 hui333 box-li-rbox-lh" style="margin-top: 36px">
            以家庭服务为应用场景，围绕服务供需两侧纵深发展，平台为用户提供家庭服务，保姆月嫂、家庭保洁、家电维修、管道疏通。通过生活服务功能实现日常烦恼全无忧，打破传统物业模式漫长的等待，旨在打造数字化、线上化、专业化、贴心化的生活服务。
          </div>
        </div>
        <div class="box-li-lbg2"></div>
      </div>
      <div class="box-li flex JCspaceBetween ALcenter">
        <div class="box-li-lbg3"></div>
        <div class="box-li-rbox">
          <div class="box-li-rbox-poa3"></div>
          <div class="fs36 fw6">邻里社交</div>
          <div class="fs24 hui999" style="margin-top: 10px">House Cleaning</div>
          <div class="fs16 hui333 box-li-rbox-lh" style="margin-top: 36px">
            实现社区轻社交，“动态发布、社区活动、二手交易、租房车位”小区动态消息全知道，让业主随时随地了解小区新消息，周末生活享不停！
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Tabbar from "@/components/Tabbar.vue";
// import Bottombox from "@/components/Bottombox.vue";
// import Fuwu from "@/components/Fuwu.vue";
import { getBanner } from "@/api/new";
export default {
  name: "Resident",
  components: {
    // Tabbar,
    // Bottombox,
    // Fuwu,
  },
  data() {
    return {
      bannerList: [{ id: 1, image: require("@/assets/juminfuwu.png") }],
      bannerdd: [],
    };
  },
  mounted() {
    console.log("进入");
    this.getbanner();
  },
  methods: {
    getbanner() {
      getBanner({ type: 2 }).then((res) => {
        console.log(res.data, "banner");
        this.bannerdd = res.data;
        if (res.data.length !== 0) {
          this.bannerList = res.data;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
#resident {
  .baner {
    width: 1920px;
    height: 576px;
    // background-image: url(~@/assets/juminfuwu.png);
    // background-size: 100% 100%;
  }

  .box {
    width: 1200px;
    margin: 140px 360px;

    .box-li {
      height: 600px;
    }

    .box-li-lbg {
      width: 620px;
      height: 448px;
      background-image: url(~@/assets/resident-1.png);
      background-size: 100% 100%;
    }
    .box-li-lbg2 {
      width: 620px;
      height: 369px;
      background-image: url(~@/assets/resident-2.png);
      background-size: 100% 100%;
    }
    .box-li-lbg3 {
      width: 620px;
      height: 336px;
      background-image: url(~@/assets/resident-3.png);
      background-size: 100% 100%;
    }

    .box-li-rbox {
      width: 440px;
      position: relative;
      .box-li-rbox-lh {
        line-height: 36px;
      }
      .box-li-rbox-poa1 {
        position: absolute;
        right: 0;
        bottom: -20%;
        width: 241px;
        height: 164px;
        background-image: url(~@/assets/resident-01.png);
        background-size: 100% 100%;
        z-index: -1;
      }
      .box-li-rbox-poa2 {
        position: absolute;
        left: 0;
        bottom: -20%;
        width: 241px;
        height: 164px;
        background-image: url(~@/assets/resident-02.png);
        background-size: 100% 100%;
        z-index: -1;
      }
      .box-li-rbox-poa3 {
        position: absolute;
        right: 0;
        bottom: -20%;
        width: 241px;
        height: 164px;
        background-image: url(~@/assets/resident-03.png);
        background-size: 100% 100%;
        z-index: -1;
      }
    }
  }
}
</style>